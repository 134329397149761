

.footer {
    background-color: #54327e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    font-size: calc(8px + 1vmin);
    padding: 20px;
    min-height: 220px;
  }
