

.SecondaryActions {
  background-color: #54327e;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(16px + 1vmin);
    color: white; */
  }

  .ikona {
    padding: 3px;
    filter: brightness(150%);
  }